import React from 'react';
import './App.css';
import founders from './images/founders.png'
import kristen_img from './images/kristen-img.png'
import eliot_img from './images/eliot-img.png'
import allhers_logo from './images/logos/allhers.png'
import contenda_logo from './images/logos/contenda.png'
import curastory_logo from './images/logos/curastory.png'
import grasshopper_logo from './images/logos/grasshopper.png'
import justice_text_logo from './images/logos/justice_text.png'
import perkies_logo from './images/logos/perkies.png'
import podmates_logo from './images/logos/podmates.png'
import thistle_logo from './images/logos/thistle.png'
import twitter_logo from './images/twitter-logo.png'
import we_the_trillions_logo from './images/logos/we_the_trillions.png'


const GET_IN_TOUCH_FORM_URL = 'https://airtable.com/shrSiET5W9x0of6WB'
const TWITTER_URL = 'https://twitter.com/GambitInvestors'
const LOGOS: any = {
  'allhers': allhers_logo,
  'contenda': contenda_logo,
  'curastory': curastory_logo,
  'grasshopper': grasshopper_logo,
  'justice_text': justice_text_logo,
  'perkies': perkies_logo,
  'podmates': podmates_logo,
  'thistle': thistle_logo,
  'we_the_trillions': we_the_trillions_logo,
}
const PORTCO_URLS: any = {
  'allhers': 'https://forallhers.com/',
  'contenda': 'https://contenda.co/',
  'curastory': 'https://curastory.co/',
  'grasshopper': 'https://www.grasshopperkids.com/',
  'justice_text': 'https://justicetext.com/',
  'perkies': 'https://perkies.com/',
  'podmates': 'https://podmates.io/',
  'thistle': 'https://www.thistle.co/',
  'we_the_trillions': 'https://www.wethetrillions.com/',
}

function getHeader() {
  return (
    <div className='header'>
      <p className='header-title'>
        gambit investments
      </p>
      <a
        className='get-in-touch get-in-touch-a-tag'
        href={GET_IN_TOUCH_FORM_URL}
        target="_blank"
      >
        <p className='get-in-touch-text'>get in touch</p>
      </a>
    </div>
  )
}

function getTitlePage() {
  return (
    <div className='title-page'>
      {getHeader()}
      <div className='main-page' >
        <div className='title-box'>
          <div className='title-definition-div'>
            <p className='title-text'>gam • bit
              <span className='title-text-italics'>(noun)</span>
            </p>
          </div>
          <br/><br/>
          <div className='title-definition-div-2'>
            <p className='title-subtext'>
              {'an '}
              <span style={{
                fontStyle: 'italic',
                background: '#40804E70',
              }}>opening</span>
              {' move or remark, typically one entailing a degree of '}
              <span style={{
                fontStyle: 'italic',
                background: '#40804E70',
              }}>risk</span>
            </p>
          </div>
          <p className='title-subtext-2'>We back founders at the earliest stages.</p>
        </div>
        <img className='founder-image' src={founders} />
      </div>
    </div>
  )
}

function getInvestorsPage() {
  return (
    <div className='meet-investors-div'>
      <div className='meet-investors-title'>
        <p className='meet-investors-text margin-horizontal'>meet the</p>
        <div>
          <p className='meet-investors-text'>investors</p>
          <div className='underline-small' />
        </div>
      </div>
    
      <div>
        <div className='image-text-div'>
          <img className='image-eliot' src={eliot_img} />
          <div >
            <p className='name-text'>Eliot Horowitz</p>
            <p className='bio-text'>{`
              Eliot is the co-founder and former CTO of MongoDB and is currently the founder of `}
              <a href='https://www.viam.com/' target="_blank" style={{color: '#DFDFDF'}}>Viam</a>
              {`, a robotics platform that relies on standardized building blocks rather than custom code to create, configure and control robots.`}
            </p>
            <br/>
            <p className='bio-text'>{`
              Eliot has scaled ideas from 0 to 1 and teams from 1 to 1,000s.
            `}</p>
          </div>
        </div>
        <div className='image-text-div' style={{justifyContent: 'right'}}>
          <div style={{textAlign: 'right'}}>
            <p className='name-text'>Kristen Mashikian</p>
            <p className='bio-text'>{`
              Kristen has been involved in the angel community through founding `}
              <a href='https://www.envisionaccelerator.com/' target='_blank' style={{color: '#DFDFDF'}}>Envision</a>
              {` and boostraping Brown Angel Group. She also has technical experience as a
              founding engineer at SaaSWorks, and is now working on product and infra at Neeva.`}
            </p>
            <br/>
            <p className='bio-text'>{`
              Kristen has worked with dozens of founders on initial product launches and fundraises.
              She's also recently been blogging about the `}
              <a href='https://coinsights.substack.com/' target="_blank" style={{color: '#DFDFDF'}}>crypto</a>
              {` space.`}
            </p>
          </div>
          <img className='image-kristen' src={kristen_img} />
        </div>
      </div>
      <div className='triangle-up'/>
      <div className='triangle-up-2'/>
      
    </div>
  )
}

function getClickableLogo(name: string): any {
  return (
    <a href={PORTCO_URLS[name]} target='_blank'>
      <img className='logo-image' src={LOGOS[name]} />
    </a>
  )
}

function getPortfolioPage() {
  return (
    <div className='portfolio-div'>
      <div className='portfolio-title'>
        <p className='portfolio-text margin-horizontal'>the gambit</p>
        <div>
          <p className='portfolio-text'>portfolio</p>
          <div className='portfolio-underline-small' />
        </div>
      </div>

      <div className='logos'>
        <div className='logo-div'>
          {getClickableLogo('justice_text')}
          {getClickableLogo('thistle')}
          {getClickableLogo('grasshopper')}
        </div>
        <div className='logo-div'>
          {getClickableLogo('contenda')}
          {getClickableLogo('podmates')}
          {getClickableLogo('allhers')}
        </div>
        <div className='logo-div'>
          {getClickableLogo('curastory')}
          {getClickableLogo('perkies')}
          {getClickableLogo('we_the_trillions')}
        </div>
      </div>

      <div className='half-circle-green' />
    </div>
  )
}

function getAboutPage() {
  return (
    <div className='about-page'>
      <div className='half-circle-white'/>
      <p className='header-title about-title'>
        about
      </p>
      <div className='about-div'>
        <div className='about-text-div'>
          <p className='about-text'>
            At Gambit, we’re committed to supporting founders at the earliest stages.
          </p>
          <p className='about-text'>
            As operators and technologists ourselves, we offer more than just capital - domain expertise; access to our network of founders, investors, operators and accelerators; and empathetic mentorship. Our people-centric and industry-agnostic thesis informs our interest in a broad range of companies from marketplaces to healthcare to SaaS.
          </p>
          <p className='about-text'>
            We lean into the chaos that comes with early-stage building and are excited to empower the visionaries we work with.
          </p>
        </div>
      </div>
    </div>
  )
}

function getFooter() {
  return (
    <div className='footer-div'>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <p className='footer footer-title'>
          gambit investments
        </p>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <a
            className='get-in-touch-a-tag'
            href={GET_IN_TOUCH_FORM_URL}
            target="_blank"
          >
            <p className='footer footer-text'>get in touch</p>
          </a>
          <a href={TWITTER_URL} target="_blank">
            <img src={twitter_logo} className='twitter-logo'/>
          </a>
        </div>
      </div>
      <p className='footer copyright-text'>copyright © 2022 gambit invetments. all rights reserved.</p>
    </div>
  )
}

function App() {
  return (
    <div>
      {getTitlePage()}
      {getInvestorsPage()}
      {getPortfolioPage()}
      {getAboutPage()}
      {getFooter()}
    </div>
  );
}

export default App;
